import '../scss/app.scss'
import '~/bootstrap-icons/font/bootstrap-icons.scss'

import './bootstrap'

import { createSSRApp, h, onMounted } from 'vue'
import { createInertiaApp, usePage, router } from '@inertiajs/vue3'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m'
import { i18nVue, loadLanguageAsync, getActiveLanguage } from 'laravel-vue-i18n'
import VueTippy from 'vue-tippy'
import { SwiperVue } from './Frontend/plugins/swiper'

import { useWindowSize } from '@vueuse/core'

import { flare } from '@flareapp/flare-client'
import { flareVue } from '@flareapp/flare-vue'

// Only enable Flare in production, we don't want to waste your quota while you're developing:
if (process.env.NODE_ENV === 'production') {
	flare.light()
}

import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { Theme } from './Frontend/plugins/theme'
import { DayJSVue } from './Frontend/plugins/dayjs'
import { ThumborVue, thumbor } from './Frontend/plugins/thumbor/thumbor'
import MainLayout from './Layouts/MainLayout.vue'
import { isClient } from './helpers'

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

const appName = import.meta.env.VITE_APP_NAME || 'Lekker'

createInertiaApp({
	title: title => `${title} - ${appName}`,
	// resolve: (name) =>
	// 	resolvePageComponent(
	// 		`./Pages/${name}.vue`,
	// 		import.meta.glob('./Pages/**/*.vue')
	// 	),
	resolve: async name => {
		const page = await resolvePageComponent(`./Frontend/Pages/${name}.vue`, import.meta.glob('./Frontend/Pages/**/*.vue'))
		page.default.layout = page.default.layout || MainLayout
		return page
	},
	setup({ el, App, props, plugin }) {
		const app = createSSRApp({
			setup() {
				const appStore = useAppStore()
				const page = usePage()

				// Set Window sizes in store
				appStore.updateWindowWidth(window.innerWidth)
				appStore.updateWindowHeight(window.innerHeight)
				const { width: windowWidth, height: windowHeight } = useWindowSize()

				watch(windowWidth, val => {
					appStore.updateWindowWidth(val)
				})

				watch(windowHeight, val => {
					appStore.updateWindowHeight(val)
					document.documentElement.style.setProperty('--doc-height', `${val}px`)
				})

				// For mobile adresbar purposes set css var
				document.documentElement.style.setProperty('--doc-height', `${appStore.windowHeight}px`)

				onMounted(() => {
					// console.log('language ON mounted', getActiveLanguage())

					if (getActiveLanguage() !== appStore.i18n) {
						loadLanguageAsync(appStore.i18n).then(() => {
							// console.log('language CHANGE mounted', getActiveLanguage())
						})
					}

					// Execute when the page has changed
					router.on('success', evt => {
						// AgeCheck on router change
						dispatchEvent(new Event('ont-age-check'))
					})

					router.on('before', evt => {
						// Keep track of route for detecting hasHistory and if previous route is results or not
						if (route().current() === 'results' && appStore.previousRoute === null) {
							console.log('Fill wizard with props')
						}
						appStore.previousRoute = route().current()
					})
				})
			},
			render: () => h(App, props),
		})
			.use(plugin)
			.use(flareVue)
			.use(ZiggyVue, Ziggy)
			.use(pinia)
			.use(VueTippy, {
				// directive: 'tippy', // => v-tippy
				// component: 'tippy', // => <tippy/>
				// componentSingleton: 'tippy-singleton', // => <tippy-singleton/>,
				defaultProps: {
					allowHTML: true,
				}, // => Global default options * see all props
			})
			.use(SwiperVue)
			.use(DayJSVue)
			.use(ThumborVue)
			.use(Theme)
			.use(i18nVue, {
				// Must be set the same as laravel lang
				lang: 'nl',
				resolve: async lang => {
					const langs = import.meta.glob('../../lang/*.json')
					// console.log(lang)
					if (langs[`../../lang/${lang}.json`]) {
						return await langs[`../../lang/${lang}.json`]()
					}
				},
				onLoad: () => {
					// console.log('Language call...')
					app.mount(el)
				},
				// lang: 'en',
				// resolve: (lang) => {
				// 	const langs = import.meta.glob('../../lang/*.json', {
				// 		eager: true,
				// 	})
				// 	return langs[`../../lang/${lang}.json`].default
				// },
			})
		// .mount(el)
		return app
	},
	progress: {
		color: isClient ? getComputedStyle(document.documentElement).getPropertyValue('--lek-primary') : '#E5006C',
		includeCSS: true,
	},
})

// Fallback on images error
if (isClient) {
	window.handleImgFallback = (image, fallback = 'https://cdn.pijper.io/core/lekker-fallback.png') => {
		// Remove picture sources
		if (image.parentElement.tagName === 'PICTURE') {
			Array.from(image.parentElement.children).map(child => {
				if (child !== image) child.remove()
			})
		}
		// Since we're using lazysizes
		image.classList.remove('lazyloaded')
		image.classList.add('lazyload')

		// Set new source
		image.dataset.src = thumbor(fallback).filters('format(webp)').url()
		// image.src = fallback
	}
}
