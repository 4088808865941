<script setup>
import { closeDialog } from '@/helpers'
import { usePage } from '@inertiajs/vue3'
const searchWizard = defineAsyncComponent(() => import('@components/SearchWizard.vue'))
const appStore = useAppStore()
const wizardStore = useWizardStore()
const page = usePage()

onMounted(() => {
	// Set autofocus on input, simple autofocus on input doesn't work well...
	const dialog = document.getElementById('search-wizard-dialog')
	// 'open' event listener doesn't excist
	const openEvent = new Event('open', { bubbles: false, cancelable: false })
	// Create observer
	const observer = new MutationObserver(items => {
		items.forEach(({ attributeName, target }) => {
			if (attributeName === 'open') {
				target.dispatchEvent(openEvent)
			}
		})
	})
	observer.observe(dialog, { attributes: true })
	dialog.addEventListener('open', () => {
		setTimeout(() => {
			dialog.querySelector('.main-input').focus()
		}, 500)
	})
})
</script>

<template>
	<dialog
		id="search-wizard-dialog"
		ref="searchWizardDialog"
		class="dialog-modal dialog-modal-top dialog-modal-bottom-sm shadow mt-3"
		style="overflow: visible; --lek-modal-max-width: 600px"
		aria-hidden="true"
		@close="
			() => {
				wizardStore.search = ''
				wizardStore.setActiveTab(0)
			}
		"
	>
		<div class="modal-content">
			<div class="modal-header justify-content-between px-md-4">
				<h4 class="modal-title">{{ $t('Search restaurants') }}</h4>
				<i
					class="str-close cursor-pointer"
					role="button"
					@click="closeDialog($refs.searchWizardDialog)"
				/>
			</div>
			<div
				class="modal-body p-md-4"
				style="overflow: visible"
			>
				<search-wizard
					:cancel-text="$t('Reset')"
					:restaurant-properties="page.props.restaurantProperties"
				/>
			</div>
		</div>
	</dialog>
</template>
