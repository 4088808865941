<script setup>
import { useForm } from '@inertiajs/vue3'

const props = defineProps({
	hpId: {
		type: String,
		default: 'hp-newsletter-signup',
	},
})

const successMsg = ref('')
const form = useForm({
	email: null,
	honeypot: null,
})

function submit() {
	const hp = document.getElementById(props.hpId)
	if (!hp || hp.value.length !== 0) {
		console.log(hp)
		return false
	}

	axios({
		method: 'POST',
		url: route('piano.newsletter-signup'),
		data: form,
	})
		.then(response => {
			form.reset()
			successMsg.value = response.data.message
		})
		.catch(error => {
			form.setError(error.response.data.errors)
		})
}
</script>

<template>
	<div
		v-if="successMsg"
		class="alert alert-success"
	>
		{{ successMsg }}
	</div>
	<form
		v-else
		@submit.prevent="submit"
	>
		<div class="h6 text-body">
			{{ $t('Sign up for our newsletter') }}
		</div>

		<div
			class="bg-white border border-neutral-300 d-flex align-items-center"
			style="border-radius: 10px; padding-right: 3px"
		>
			<input
				v-model="form.email"
				type="email"
				class="form-control border-0 bg-transparent text-body"
				:placeholder="$t('E-mail address')"
				required
				style="box-shadow: none"
			/>
			<button
				class="btn btn-neutral-900 fw-bolder py-2"
				type="submit"
				:disabled="!form.isDirty || form.processing"
				style="white-space: nowrap"
			>
				{{ $t('Sign up') }}
			</button>
		</div>

		<small
			v-if="form.errors.email"
			class="text-danger"
		>
			{{ form.errors.email[0] }}
		</small>

		<input
			:id="hpId"
			v-model="form.honeypot"
			type="text"
			style="display: none; height: 0; width: 0"
		/>
	</form>
</template>
