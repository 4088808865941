// import { clearQueueScrollLocks, disablePageScroll, enablePageScroll } from 'scroll-lock'
import scrollLock from 'scroll-lock'
const { clearQueueScrollLocks, disablePageScroll, enablePageScroll } = scrollLock

const getMeta = (meta, key) => meta.filter(obj => obj.key === key)[0]

const uuid = (prefix = '') => `${prefix}${Date.now().toString(36) + Math.random().toString(36).substr(2)}`

const randomHex = () => `#${Math.floor(Math.random() * 16777215).toString(16)}`

const isClient = typeof window !== 'undefined'

function closeAllDialogs() {
	Array.from(document.querySelectorAll('.dialog-modal[open]'))
		.reverse()
		.map(d => {
			d.classList.add('hide')

			d.addEventListener(
				'animationend',
				// addHide(d),
				function callback() {
					d.classList.remove('hide')
					d.close()
					//d.removeEventListener('webkitAnimationEnd', arguments.callee, false)
					d.removeEventListener('animationend', callback, false)
					d.setAttribute('aria-hidden', true)
				},
				false
			)
		})

	// document.body.classList.remove('dialog-modal-open')
	// document.documentElement.classList.remove('dialog-modal-open')
	clearQueueScrollLocks()
	enablePageScroll()
}

function openDialog(ref) {
	// For now we close all open dialogs first
	closeAllDialogs()

	ref.showModal()
	ref.setAttribute('aria-hidden', false)

	disablePageScroll()
	// document.body.classList.add('dialog-modal-open')
	// document.documentElement.classList.add('dialog-modal-open')

	// Add eventListener for Escape
	ref.addEventListener('cancel', e => {
		e.preventDefault()
		closeAllDialogs()
	})

	// Add eventListener for click (outside) dialog element
	document.addEventListener('click', e => {
		if (e.target.classList.contains('dialog-modal') && e.target.hasAttribute('open')) {
			e.preventDefault()
			closeAllDialogs()
		}
	})
}

function openDialogById(modal) {
	// For now we close all open dialogs first
	closeAllDialogs()

	const m = document.getElementById(modal)

	if (!m) return

	m.showModal()
	m.setAttribute('aria-hidden', false)

	disablePageScroll()
	//document.body.classList.add('dialog-modal-open')

	// Add eventListener for Escape
	m.addEventListener('cancel', e => {
		e.preventDefault()
		closeAllDialogs()
	})

	// Add eventListener for click (outside) dialog element
	document.addEventListener('click', e => {
		if (e.target.classList.contains('prevent-modal-close')) {
			// Doesn't work!
			// e.preventDefault()
			return
		}

		if (e.target.classList.contains('dialog-modal') && e.target.hasAttribute('open')) {
			e.preventDefault()
			closeAllDialogs()
		}
	})
}

function closeDialog(ref) {
	// Maybe change this later on to single close instead of all dialogs
	closeAllDialogs()
	// ref.close()
}

function sanitizeHTML(content, options = {}) {
	return content
		.replace(/<\/?span[^>]*>/g, '')
		.replace(/<\/?p[^>]*>/g, '')
		.replace(/&nbsp;/g, ' ')
}

function ratio16by9(value) {
	return Math.round((value / 16) * 9)
}

function ratio(value, first = 16, second = 9) {
	return Math.round((value / first) * second)
}

function transformUrl(url, prefix = '//') {
	// Replace http(s)://www
	return url.replace(/http(s)?(:)?(\/\/)?|(\/\/)?(www\.)?/, prefix)
}

function wordCount(string) {
	return string.trim().split(/\s+/).length
}

function truncate(str, no_words, after = '...') {
	const truncated = str.split(' ').splice(0, no_words).join(' ')

	if (truncated === str) return str
	return `${str.split(' ').splice(0, no_words).join(' ')}${after}`
}

function debounce(func, wait, immediate) {
	var timeout

	return function executedFunction() {
		var context = this
		var args = arguments

		var later = function () {
			timeout = null
			if (!immediate) func.apply(context, args)
		}

		var callNow = immediate && !timeout

		clearTimeout(timeout)

		timeout = setTimeout(later, wait)

		if (callNow) func.apply(context, args)
	}
}

function groupBy(array, key) {
	return array.reduce((result, currentValue) => {
		// If an array already present for key, push it to the array. Else create an array and push the object
		;(result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue)
		// Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
		return result
	}, {}) // empty object is the initial value for result object
}

function waitForCondition(variable, scope = window, timeout = 10) {
	function waitFor(result) {
		if (result) {
			return result
		}
		return new Promise(resolve => setTimeout(resolve, timeout)).then(() => Promise.resolve(scope[variable])).then(res => waitFor(res))
	}
	return waitFor()
}

function createArray(number) {
	return Array.apply(null, Array(number)).map((x, i) => i)
}

function chunkArray(arr, len = 10) {
	let chunks = [],
		i = 0,
		n = arr.length

	while (i < n) {
		chunks.push(arr.slice(i, (i += len)))
	}

	return chunks
}

function capitalize(string) {
	return `${string[0].toUpperCase()}${string.slice(1)}`
}

export {
	getMeta,
	uuid,
	randomHex,
	isClient,
	openDialog,
	openDialogById,
	closeDialog,
	closeAllDialogs,
	sanitizeHTML,
	ratio16by9,
	ratio,
	transformUrl,
	truncate,
	wordCount,
	debounce,
	groupBy,
	waitForCondition,
	createArray,
	chunkArray,
	capitalize,
}
