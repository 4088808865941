//  Use custom elements for swipers: https://swiperjs.com/element#usage-with-vue
import { register } from 'swiper/element/bundle'

const SwiperVue = {
	install(app, options) {
		// Compiler options defined in vite config
		register()
	},
}

export { SwiperVue }
