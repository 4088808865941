<script setup>
import { usePage } from '@inertiajs/vue3'

const page = usePage()
</script>

<template>
	<slot
		v-if="page.props.auth.user"
		name="auth"
		:user="page.props.auth.user"
	></slot>
	<slot v-else />
</template>
