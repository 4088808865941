import { defineStore, acceptHMRUpdate } from 'pinia'
import { prefix } from './config'

const storageKey = `${prefix}app`
// const app = JSON.parse(localStorage.getItem(storageKey))

import { breakpoint_xs as breakpointXs, breakpoint_sm as breakpointSm, breakpoint_md as breakpointMd, breakpoint_lg as breakpointLg, breakpoint_xl as breakpointXl, breakpoint_xxl as breakpointXxl } from '../../../scss/_config.module.scss'

import { loadLanguageAsync } from 'laravel-vue-i18n'

// import MobileDetect from 'mobile-detect'

export const useAppStore = defineStore(
	'app',
	() => {
		// State
		const i18n = ref('nl')
		// const translationsEnabled = ref(false)
		const windowWidth = ref(0)
		const windowHeight = ref(0)
		const homeWizardVisible = ref(false)
		const breakpoints = ref({
			xs: Number(breakpointXs),
			sm: Number(breakpointSm),
			md: Number(breakpointMd),
			lg: Number(breakpointLg),
			xl: Number(breakpointXl),
			xxl: Number(breakpointXxl),
		})
		const previousRoute = ref(null)

		// Getters
		const getWindowWidth = computed(() => windowWidth.value)
		const getBreakpoints = computed(() => Object.values(breakpoints.value).reverse())

		const isMobile = computed(() => windowWidth.value <= breakpoints.value.md)
		const isTablet = computed(() => windowWidth.value <= breakpoints.value.lg)
		const isDesktop = computed(() => windowWidth.value > breakpoints.value.lg)

		// check for old values from Piano composer or set default
		// const oldConsent = JSON.parse(localStorage.getItem('__ont-age-consent'))
		const ageConsent = ref({
			shown: false,
			value: false,
		})

		// if (oldConsent) {
		// 	localStorage.removeItem('__ont-age-consent')
		// }

		const translationsEnabled = computed(() => !!import.meta.env.VITE_TRANSLATIONS_ENABLED || false)
		// Actions
		function updateWindowWidth(val) {
			windowWidth.value = val
		}

		function updateWindowHeight(val) {
			windowHeight.value = val
		}

		watch(i18n, (newVal, oldVal) => {
			// Set language, also sets lang on html element
			loadLanguageAsync(newVal)
		})

		// function test({ req }) {
		// 	const md = new MobileDetect(req.headers['user-agent'])
		// 	console.log(md)
		// }

		// test()

		return {
			i18n,
			translationsEnabled,
			windowWidth,
			windowHeight,
			breakpoints,
			homeWizardVisible,
			previousRoute,

			getWindowWidth,
			getBreakpoints,

			isMobile,
			isTablet,
			isDesktop,

			ageConsent,

			updateWindowWidth,
			updateWindowHeight,
		}
	},
	{
		persist: [
			{
				key: storageKey,
				storage: typeof window !== 'undefined' ? localStorage : null,
				paths: ['i18n', 'ageConsent'],
			},
			{
				key: storageKey,
				storage: typeof window !== 'undefined' ? sessionStorage : null,
				paths: ['previousRoute'],
			},
		],
	}
)

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot))
}
