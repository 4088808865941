import { registerPluginDirectives } from './directives'
import { registerPluginComponents, registerPluginAsyncComponents } from './components'

const Theme = {
	install(app, options) {
		/* Register components */
		registerPluginComponents(app)
		registerPluginAsyncComponents(app)

		/* Register directives */
		registerPluginDirectives(app)

		/* Other */
		app.config.globalProperties.$nossr = typeof window !== 'undefined' ? true : false

		Array.prototype.remove = function () {
			var what,
				a = arguments,
				L = a.length,
				ax
			while (L && this.length) {
				what = a[--L]
				while ((ax = this.indexOf(what)) !== -1) {
					this.splice(ax, 1)
				}
			}
			return this
		}
	},
}

export { Theme }
