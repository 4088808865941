import dayjs from 'dayjs'
import 'dayjs/locale/nl'
import 'dayjs/locale/de'
// import 'dayjs/locale/en' // = fallback

const DayJSVue = {
	install(app, options) {
		app.provide('dayjs', dayjs)
	},
}

export { DayJSVue }
