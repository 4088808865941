<script setup>
import { closeDialog, openDialogById } from '@/helpers'
import { useForm } from '@inertiajs/vue3'
// import { router } from '@inertiajs/vue3'
// import { Inertia } from '@inertiajs/inertia'

const appStore = useAppStore()

const form = useForm({
	email: null,
})

// const preview = ref(false)

const confirmationMsg = ref(null)

const submit = () => {
	form.post(route('password.email'))
}

onMounted(() => {
	document.getElementById('forgot-password-modal-dialog').addEventListener('close', () => {
		form.reset()
		form.clearErrors()
		confirmationMsg.value = null
	})
})
</script>

<template>
	<dialog
		id="forgot-password-modal-dialog"
		ref="forgotPasswordModalDialog"
		class="dialog-modal dialog-modal-bottom-sm shadow"
		style="--lek-modal-border-width: 0px"
		aria-hidden="true"
	>
		<div class="modal-content">
			<div class="modal-header d-block border-0 pb-0">
				<div class="text-end">
					<i
						class="str-close cursor-pointer"
						role="button"
						@click="
							() => {
								closeDialog($refs.forgotPasswordModalDialog)
								form.clearErrors()
								form.reset()
							}
						"
					/>
				</div>
				<h4 class="modal-title fs-4 mt-n3 mb-0">{{ $t('Reset password') }}</h4>
			</div>

			<div class="modal-body pt-0">
				<p class="text-neutral-700">{{ $t('passwords.forgottentext') }}</p>
				<div
					v-if="confirmationMsg"
					class="alert alert-success mb-0"
				>
					{{ $t(confirmationMsg) }}
				</div>

				<form
					v-else
					@submit.prevent="submit()"
				>
					<div class="row">
						<div class="col-12 mb-3">
							<input
								v-model="form.email"
								type="email"
								class="form-control"
								:placeholder="$t('Email')"
								required
							/>
							<div v-if="form.errors.email">
								<small
									v-for="(emailError, emailErrorIndex) in form.errors.email"
									:key="`email-error-${emailErrorIndex}`"
									class="text-danger"
									>{{ $t(emailError) }}</small
								>
							</div>
						</div>
						<div class="col-12 d-flex">
							<button
								type="submit"
								:disabled="form.processing"
								class="btn btn-gold-500 fw-bold prevent-modal-close"
							>
								{{ $t('Send') }}
							</button>
							<div class="flex-grow-1 text-end">
								<a
									href="javascript:void(0);"
									role="button"
									class="feature-link"
									@click="openDialogById('login-modal-dialog')"
								>
									{{ $t('Go to login') }}
								</a>
							</div>
						</div>
					</div>
				</form>
			</div>
		</div>
	</dialog>
</template>
