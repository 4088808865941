<script setup>
import { Link, usePage } from '@inertiajs/vue3'
import { Inertia } from '@inertiajs/inertia'
import { isClient, closeAllDialogs, openDialogById } from '@/helpers'
import { mainNavigation, globalNavigation, legalNavigation } from '@/navigation'

// import languageDropdown from '@components/LanguageDropdown.vue'
import newsletterSignup from '@components/NewsletterSignup.vue'
import mainNavDialog from '@components/MainNavDialog.vue'
import searchWizardDialog from '@components/SearchWizardDialog.vue'
import loginModalDialog from '@components/account/LoginModalDialog.vue'
import registerModalDialog from '@components/account/RegisterModalDialog.vue'
import forgotPasswordModalDialog from '@components/account/ForgotPasswordModalDialog.vue'
import ageConsentDialog from '@components/AgeConsentDialog.vue'

// const languageDropdown = defineAsyncComponent(() => import('@components/LanguageDropdown.vue'))
// const mainNavDialog = defineAsyncComponent(() => import('@components/MainNavDialog.vue'))
// const searchWizardDialog = defineAsyncComponent(() => import('@components/SearchWizardDialog.vue'))
// const loginModalDialog = defineAsyncComponent(() => import('@components/account/LoginModalDialog.vue'))
// const registerModalDialog = defineAsyncComponent(() => import('@components/account/RegisterModalDialog.vue'))
// const forgotPasswordModalDialog = defineAsyncComponent(() => import('@components/account/ForgotPasswordModalDialog.vue'))

const page = usePage()
const wizardStore = useWizardStore()
const appStore = useAppStore()

if (isClient) {
	// Watch for route changes
	Inertia.on('start', event => {
		closeAllDialogs()
		wizardStore.activeTab = 0
	})

	watch(
		() => page.props.isLogin,
		isLogin => {
			if (isLogin) {
				setTimeout(() => {
					openDialogById('login-modal-dialog')
				}, 500)
			}
		},
		{
			immediate: true,
		}
	)
}
</script>

<template>
	<div
		class="position-sticky bg-white shadow-sm"
		style="top: 0; z-index: 2000"
	>
		<div class="container-xxl main-wrapper">
			<div class="topbar">
				<Link href="/">
					<img
						src="../../_materiaal/lekker-logo.svg"
						width="85"
						height="28"
						alt="Lekker logo"
					/>
				</Link>

				<div
					v-show="appStore.getWindowWidth >= appStore.breakpoints.lg"
					class="text-center"
				>
					<transition>
						<div
							v-if="!appStore.homeWizardVisible"
							class="position-relative cursor-pointer"
						>
							<input
								type="text"
								class="form-control icon-left icon-search bg-white"
								:placeholder="$t('Where do you want to eat?')"
								disabled
							/>
							<div
								class="stretched-link"
								@click="openDialogById('search-wizard-dialog')"
							></div>
						</div>
					</transition>
				</div>

				<nav class="d-flex justify-content-end align-items-center gap-2 gap-md-3">
					<i
						v-show="!appStore.isDesktop && !appStore.homeWizardVisible"
						class="str-search cursor-pointer"
						style="font-size: 24px; margin-top: 4px"
						role="button"
						:aria-label="$t('Search for location or restaurant')"
						@click="openDialogById('search-wizard-dialog')"
					/>

					<!-- <language-dropdown v-if="appStore.translationsEnabled" /> -->
					<!-- <language-dropdown /> -->

					<img
						src="../../_materiaal/flags/NL.svg"
						width="24"
						height="24"
						class="rounded-circle border border-neutral-100"
						:alt="$t('flag')"
					/>

					<auth-check>
						<template #auth="{ user }">
							<Link
								as="button"
								:href="route('profile.edit')"
								class="btn btn-outline-neutral-900 btn-sm-sm fw-bold d-flex align-items-center gap-2 py-2"
							>
								<i class="str-union" />
								<span class="d-none d-md-inline">{{ $t('Account') }}</span>
							</Link>
						</template>
						<Link
							role="button"
							:href="route('register')"
							class="btn btn-gold-500 btn-sm-sm fw-bold py-2"
						>
							{{ $t('Register') }}
						</Link>
						<a
							role="button"
							href="javascript:void(0);"
							class="btn btn-outline-neutral-900 fw-bold py-2 d-none d-md-flex"
							@click="openDialogById('login-modal-dialog')"
						>
							{{ $t('Login') }}
						</a>
					</auth-check>

					<i
						class="str-menu cursor-pointer"
						style="font-size: 24px"
						role="button"
						:aria-label="$t('Open menu')"
						@click="openDialogById('main-nav-dialog')"
					/>
				</nav>
			</div>
		</div>
	</div>
	<slot />

	<footer class="bg-gold-500">
		<div class="container-xxl main-wrapper py-4 py-md-5">
			<div class="row">
				<div class="col-12 d-flex justify-content-between mb-3">
					<Link href="/">
						<img
							src="../../_materiaal/lekker-logo.svg"
							width="85"
							height="28"
							loading="lazy"
							alt="Lekker logo"
						/>
					</Link>
					<ul class="list-unstyled d-flex gap-3">
						<li>
							<a
								href="https://www.instagram.com/lekker500"
								target="_blank"
								class="text-body text-decoration-none"
								:aria-label="$t('Go to :social', { social: 'Instagram' })"
							>
								<i class="str-instagram-fill fs-4" />
							</a>
						</li>
						<li>
							<a
								href="https://www.facebook.com/Lekkernl-143829779118162/"
								target="_blank"
								class="text-body text-decoration-none"
								:aria-label="$t('Go to :social', { social: 'Facebook' })"
							>
								<i class="str-facebook-fill fs-4" />
							</a>
						</li>
						<li>
							<a
								href="https://www.twitter.com/Lekker500"
								target="_blank"
								class="text-body text-decoration-none"
								:aria-label="$t('Go to :social', { social: 'Twitter' })"
							>
								<i class="str-x-com fs-4" />
							</a>
						</li>
					</ul>
				</div>
			</div>
			<div class="row justify-content-between my-3 my-md-4">
				<div class="col-md-4 mb-3">
					<span class="h3 ff-serif">{{ $t('theme.maintitle') }}</span>
				</div>
				<div class="col-md-4 mb-3">
					<div class="h6 mb-0">Tip voor een restaurant?</div>
					<p>
						Mail dan naar
						<a
							href="mailto:tip@lekker.nl"
							target="_blank"
							class="text-body"
							>tip@lekker.nl</a
						>
					</p>
				</div>
				<div class="col-md-4 mb-3">
					<newsletter-signup
						:style="{
							maxWidth: !appStore.isMobile ? '315px' : null,
						}"
					/>
				</div>
			</div>
			<div class="row">
				<div class="col-6 col-md-4">
					<div class="h6 mb-1">{{ $t('Menu') }}</div>
					<ul class="list-unstyled lh-3">
						<li
							v-for="mainLink in mainNavigation"
							:key="`main.${mainLink.label}`"
						>
							<span
								v-if="mainLink.label === 'navigation.main.search'"
								class="text-body fs-7 cursor-pointer hover-underline"
								@click="openDialogById('search-wizard-dialog')"
							>
								{{ $t(mainLink.label) }}
							</span>
							<Link
								v-else-if="!mainLink.external"
								:href="mainLink.route"
								class="text-body fs-7"
							>
								{{ $t(mainLink.label) }}
							</Link>
							<a
								v-else
								:href="mainLink.route"
								class="text-body fs-7"
								target="_blank"
							>
								{{ $t(mainLink.label) }}
							</a>
						</li>
					</ul>
				</div>
				<div class="col-6 col-md-4">
					<h6 class="mb-1">{{ $t('General') }}</h6>
					<ul class="list-unstyled lh-3">
						<li
							v-for="globalLink in globalNavigation"
							:key="`global.${globalLink.label}`"
						>
							<Link
								v-if="!globalLink.external"
								:href="globalLink.route"
								class="text-body fs-7"
							>
								{{ $t(globalLink.label) }}
							</Link>
							<a
								v-else
								:href="globalLink.label !== 'navigation.global.orderlekker500' ? globalLink.route : page.props?.edition.properties.purchase_link"
								class="text-body fs-7"
								target="_blank"
							>
								{{ $t(globalLink.label) }}
							</a>
						</li>
					</ul>
				</div>
				<div class="col-6 col-md-4">
					<h6 class="mb-1">{{ $t('Legal') }}</h6>
					<ul class="list-unstyled lh-3">
						<li
							v-for="legalLink in legalNavigation"
							:key="`legal.${legalLink.label}`"
						>
							<Link
								v-if="!legalLink.external"
								:href="legalLink.route"
								class="text-body fs-7"
							>
								{{ $t(legalLink.label) }}
							</Link>
							<a
								v-else
								:href="legalLink.route"
								class="text-body fs-7"
								target="_blank"
							>
								{{ $t(legalLink.label) }}
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</footer>

	<main-nav-dialog :navigation="mainNavigation" />
	<search-wizard-dialog />

	<client-only>
		<age-consent-dialog />
	</client-only>

	<auth-check>
		<login-modal-dialog />
		<register-modal-dialog />
		<forgot-password-modal-dialog />
	</auth-check>
	<!-- <teleport to="body">
	</teleport> -->
</template>
