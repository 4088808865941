// Note: uses translation files dot notation!
const mainNavigation = [
	{ label: 'navigation.main.home', route: '/', component: 'Home/Index', external: false },
	{ label: 'navigation.main.search', route: 'javascript:void(0);', component: 'SearchResult/Index', external: false },
	{ label: 'navigation.main.restaurants', route: '/results', component: 'SearchResult/Index' },
	{ label: 'navigation.main.top500', route: '/restaurant/klassering', component: 'SearchResult/Index', external: false },
	// Todo: Set dynamic!
	{ label: 'navigation.main.news', route: '/nieuws', component: 'Category/Index', external: false },
	{ label: 'navigation.main.inspiration', route: '/inspiratie', component: 'Category/Index', external: false },
	{ label: 'navigation.main.tips', route: '/tips', component: 'Category/Index', external: false },
	{ label: 'navigation.main.nhgp', route: '/nhgp', component: 'Category/Index', external: false },
]

const globalNavigation = [
	{ label: 'navigation.global.contact', route: '/contact', component: 'Page/Index', external: false },
	{ label: 'navigation.global.advertise', route: 'https://www.pijpermedia.nl/merken/lekker', component: 'Page/Index', external: true },
	{ label: 'navigation.global.colophon', route: '/colofonlekker500', component: 'Page/Index', external: false },
	// { label: 'navigation.global.mediakit', route: 'https://pijpermedia.nl/titel/lekker', component: '', external: true },
	{ label: 'navigation.global.orderlekker500', route: 'https://tijdschrift.land/product/lekker500-2024/', component: '', external: true },
]

const legalNavigation = [
	{ label: 'navigation.legal.copyright', route: '/copyright', component: 'Page/Index', external: false },
	{ label: 'navigation.legal.terms', route: '/voorwaarden', component: 'Page/Index', external: false },
	{ label: 'navigation.legal.disclaimer', route: 'https://pijpermedia.nl/disclaimer', component: '', external: true },
	{ label: 'navigation.legal.customerservice', route: '/klantenservice', component: 'Page/Index', external: false },
	{ label: 'navigation.legal.privacy', route: 'https://pijpermedia.nl/privacy-en-cookiebeleid', component: '', external: true },
]

export { mainNavigation, globalNavigation, legalNavigation }
