// Note: Some components can not be async due to shifting the layout!

import AuthCheck from './AuthCheck.vue'
import ThemeBaseSwiper from './ThemeBaseSwiper.vue'
import ThemeRankingLabel from './labels/ThemeRankingLabel.vue'
// import Translation from './Translation.vue'

const registerPluginComponents = app => {
	app.component(
		'ClientOnly',
		defineComponent({
			setup(_, { slots }) {
				const show = ref(false)
				onMounted(() => {
					show.value = true
				})
				return () => (show.value && slots.default ? slots.default() : null)
			},
		})
	)

	// app.component('Trans', defineComponent(Translation))
	app.component('AuthCheck', defineComponent(AuthCheck))
	app.component('ThemeBaseSwiper', defineComponent(ThemeBaseSwiper))
	app.component('ThemeRankingLabel', defineComponent(ThemeRankingLabel))
}

const registerPluginAsyncComponents = app => {
	// app.component(
	// 	'ThemeBaseSwiper',
	// 	defineAsyncComponent({
	// 		loader: () => import('./ThemeBaseSwiper.vue'),
	// 	})
	// )

	app.component(
		'ThemePropertyBadge',
		defineAsyncComponent({
			loader: () => import('./badges/ThemePropertyBadge.vue'),
		})
	)

	app.component(
		'ThemeActionBadge',
		defineAsyncComponent({
			loader: () => import('./badges/ThemeActionBadge.vue'),
		})
	)

	app.component(
		'ThemeSelectBadge',
		defineAsyncComponent({
			loader: () => import('./badges/ThemeSelectBadge.vue'),
		})
	)

	app.component(
		'ThemeShareList',
		defineAsyncComponent({
			loader: () => import('./ThemeShareList.vue'),
		})
	)

	// app.component(
	// 	'AuthCheck',
	// 	defineAsyncComponent({
	// 		loader: () => import('./AuthCheck.vue'),
	// 	})
	// )

	app.component(
		'ThemeCardRestaurant',
		defineAsyncComponent({
			loader: () => import('./cards/ThemeCardRestaurant.vue'),
			loaderComponent: () => import('./Loader.vue'),
		})
	)

	app.component(
		'ThemeCardRestaurantResult',
		defineAsyncComponent({
			loader: () => import('./cards/ThemeCardRestaurantResult.vue'),
			loaderComponent: () => import('./Loader.vue'),
			// delay: 0,
		})
	)

	app.component(
		'ThemeCardHighlight',
		defineAsyncComponent({
			loader: () => import('./cards/ThemeCardHighlight.vue'),
			loaderComponent: () => import('./Loader.vue'),
		})
	)

	app.component(
		'ThemeCardPlain',
		defineAsyncComponent({
			loader: () => import('./cards/ThemeCardPlain.vue'),
			loaderComponent: () => import('./Loader.vue'),
		})
	)

	app.component(
		'ThemeCardRecipe',
		defineAsyncComponent({
			loader: () => import('./cards/ThemeCardRecipe.vue'),
			loaderComponent: () => import('./Loader.vue'),
		})
	)

	app.component(
		'ThemeCardCityGuide',
		defineAsyncComponent({
			loader: () => import('./cards/ThemeCardCityGuide.vue'),
			loaderComponent: () => import('./Loader.vue'),
		})
	)

	app.component(
		'ThemeCardTip',
		defineAsyncComponent({
			loader: () => import('./cards/ThemeCardTip.vue'),
			loaderComponent: () => import('./Loader.vue'),
		})
	)

	app.component(
		'ThemeCardBook',
		defineAsyncComponent({
			loader: () => import('./cards/ThemeCardBook.vue'),
			loaderComponent: () => import('./Loader.vue'),
		})
	)

	app.component(
		'ThemeCardInstagram',
		defineAsyncComponent({
			loader: () => import('./cards/ThemeCardInstagram.vue'),
			loaderComponent: () => import('./Loader.vue'),
		})
	)

	// app.component(
	// 	'SwiperHeader',
	// 	defineAsyncComponent({
	// 		loader: () => import('./SwiperHeader.vue'),
	// 		loaderComponent: () => import('./Loader.vue'),
	// 	})
	// )

	// app.component(
	// 	'ThemeRankingLabel',
	// 	defineAsyncComponent({
	// 		loader: () => import('./labels/ThemeRankingLabel.vue'),
	// 	})
	// )

	app.component(
		'ThemeVideoLabel',
		defineAsyncComponent({
			loader: () => import('./labels/ThemeVideoLabel.vue'),
		})
	)

	app.component(
		'ThemeReviewSwiper',
		defineAsyncComponent({
			loader: () => import('./ThemeReviewSwiper.vue'),
			loaderComponent: () => import('./Loader.vue'),
		})
	)

	app.component(
		'ThemeImagesSwiper',
		defineAsyncComponent({
			loader: () => import('./ThemeImagesSwiper.vue'),
			loaderComponent: () => import('./Loader.vue'),
		})
	)

	app.component(
		'ThemeMarkerButton',
		defineAsyncComponent({
			loader: () => import('./ThemeMarkerButton.vue'),
		})
	)
}

export { registerPluginComponents, registerPluginAsyncComponents }
