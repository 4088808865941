import { defineStore, acceptHMRUpdate } from 'pinia'
import { usePage } from '@inertiajs/vue3'
import { groupBy } from '@/helpers'
import { prefix } from './config'
// import { debounce } from 'lodash'
import debounce from 'lodash/debounce'
// const { debounce } = lodashDebounce
// console.log(debounce)
import { trans } from 'laravel-vue-i18n'
const storageKey = `${prefix}wizard`
// const app = JSON.parse(localStorage.getItem(storageKey))

export const useWizardStore = defineStore(
	'wizard',
	() => {
		const search = ref('')
		const activeTab = ref(0)
		const visitedTabs = ref([])

		const pickedOrder = ref([])

		const locations = ref({})
		const foodProperties = ref([])
		const howProperties = ref([])
		const partnershipProperties = ref([])
		const valuationProperties = ref([])
		const locationSuggestions = ref([])

		const resultCount = ref(0)

		const queryString = computed(() => {
			let query = new URLSearchParams()

			Object.values(pickedOrder.value).map(q => {
				const [item, value] = q.split('=')
				query.append(item, value)
			})

			return query
		})

		// Getters
		const query = computed(() => {
			return `/results?${queryString.value.toString()}`
		})

		const countQuery = computed(() => {
			const newQuery = new URLSearchParams(queryString.value.toString())
			newQuery.append('count', true)

			return `/results?${newQuery.toString()}`
		})

		const groupedRestaurantProperties = computed(() => {
			const page = usePage()
			return groupBy(Object.values(page.props.restaurantProperties), 'type')
		})

		const totalFiltersCount = computed(() => {
			return Object.keys(locations.value).length + foodProperties.value.length + howProperties.value.length + partnershipProperties.value.length + valuationProperties.value.length
		})

		const selectedProperties = computed(() => {
			const page = usePage()

			const res = []

			pickedOrder.value.map(param => {
				const [key, id] = param.split('=')

				if (key === 'f[]' || key === 'p[]') {
					const item = page.props.restaurantProperties[id]

					res.push({
						displayValue: trans(`property.${item.value}`),
						handleAs: 'property',
						...item,
					})
				} else if (key === 'ps[]') {
					const item = groupedRestaurantProperties.value.partnership.filter(p => p.id === Number(id))[0]

					res.push({
						displayValue: item.value,
						handleAs: 'property',
						...item,
					})
				} else if (key === 'c[]') {
					const item = groupedRestaurantProperties.value.valuation.filter(p => p.value === id)[0]
					const addon = /m:/.test(item.value) ? ` (${item.value.replace('m:', '')})` : ''

					res.push({
						displayValue: trans(`property.${item.value}`) + addon,
						handleAs: 'property',
						...item,
					})
				} else if (key === 'l[]') {
					const item = locations.value[id]

					res.push({
						displayValue: item.name,
						handleAs: 'location',
						...item,
					})
				}
			})
			return res.reverse()
		})

		// Actions
		async function fetchLocations(input) {
			if (!input) {
				locationSuggestions.value = []
				return
			}

			if (typeof window !== 'undefined') {
				const res = await axios.get(`/api/locations`, { params: { term: input } })
				locationSuggestions.value = res && res.data ? res.data : []
			}
		}

		async function fetchResultCount() {
			if (typeof window !== 'undefined') {
				const res = await axios.get(countQuery.value)

				resultCount.value = res.data.count
			}
		}

		const debouncedResultCount = debounce(fetchResultCount, 500)

		watch(query, () => debouncedResultCount(), { immediate: true })

		function addOrRemoveProperty(item, wizardPropertySet = 'foodProperties') {
			switch (wizardPropertySet) {
				case 'foodProperties':
					item.checked === true ? pickedOrder.value.push(`f[]=${item.id}`) : pickedOrder.value.remove(`f[]=${item.id}`)
					break
				case 'howProperties':
					item.checked === true ? pickedOrder.value.push(`p[]=${item.id}`) : pickedOrder.value.remove(`p[]=${item.id}`)
					break
				case 'valuationProperties':
					item.checked === true ? pickedOrder.value.push(`c[]=${item.id}`) : pickedOrder.value.remove(`c[]=${item.id}`)
					break
				case 'partnershipProperties':
					item.checked === true ? pickedOrder.value.push(`ps[]=${item.id}`) : pickedOrder.value.remove(`ps[]=${item.id}`)
					break
				case 'locationProperties':
					item.checked === true ? pickedOrder.value.push(`l[]=${item.id}`) : pickedOrder.value.remove(`l[]=${item.id}`)
					break
			}
		}

		function deleteProp(identifier, handle = 'property') {
			if (handle === 'property') {
				if (foodProperties.value.includes(identifier)) {
					foodProperties.value.remove(identifier)
					pickedOrder.value.remove(`f[]=${identifier}`)
				}

				if (howProperties.value.includes(identifier)) {
					howProperties.value.remove(identifier)
					pickedOrder.value.remove(`p[]=${identifier}`)
				}

				if (partnershipProperties.value.includes(identifier)) {
					partnershipProperties.value.remove(identifier)
					pickedOrder.value.remove(`ps[]=${identifier}`)
				}

				if (valuationProperties.value.includes(identifier)) {
					valuationProperties.value.remove(identifier)
					pickedOrder.value.remove(`c[]=${identifier}`)
				}
			} else if (handle === 'location') {
				delete locations.value[identifier]
				addOrRemoveProperty({ checked: false, id: identifier }, 'locationProperties')
			}
		}

		function setActiveTab(tab) {
			activeTab.value = tab
		}

		function clearAll() {
			pickedOrder.value = []
			foodProperties.value = []
			howProperties.value = []
			partnershipProperties.value = []
			valuationProperties.value = []
			locations.value = {}
		}

		function $reset() {
			search.value = ''
			activeTab.value = 0
			visitedTabs.value = []

			pickedOrder.value = []

			locations.value = {}
			foodProperties.value = []
			howProperties.value = []
			partnershipProperties.value = []
			valuationProperties.value = []
			locationSuggestions.value = []

			// sessionStorage.removeItem(storageKey)
		}

		return {
			search,
			activeTab,
			visitedTabs,

			pickedOrder,

			locations,
			foodProperties,
			howProperties,
			partnershipProperties,
			valuationProperties,
			locationSuggestions,

			query,
			groupedRestaurantProperties,
			totalFiltersCount,
			resultCount,

			fetchLocations,
			setActiveTab,
			clearAll,

			$reset,
			addOrRemoveProperty,
			selectedProperties,
			deleteProp,
		}
	},
	{
		persist: {
			key: storageKey,
			storage: typeof window !== 'undefined' ? sessionStorage : null,
			paths: ['pickedOrder', 'locations', 'foodProperties', 'howProperties', 'partnershipProperties', 'valuationProperties'],
		},
	}
)

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useWizardStore, import.meta.hot))
}
