<script setup>
import { closeDialog, openDialogById } from '@/helpers'
import { Link, useForm, usePage } from '@inertiajs/vue3'
// import { router } from '@inertiajs/vue3'
// import { Inertia } from '@inertiajs/inertia'

const page = usePage()

const status = computed(() => page.props.status)

const appStore = useAppStore()

const form = useForm({
	// email: 'test@pijpermedia.nl',
	// password: 'Myvisual1980',
	email: null,
	password: null,
})

const preview = ref(false)

const submit = () => {
	form.post(route('login'), {
		onError: () => {
			// Reopen dialog
			openDialogById('login-modal-dialog')
		},
	})
}

onMounted(() => {
	document.getElementById('login-modal-dialog').addEventListener('close', () => {
		form.reset()
		form.clearErrors()
	})
})
</script>

<template>
	<dialog
		id="login-modal-dialog"
		ref="loginModalDialog"
		class="dialog-modal dialog-modal-bottom-sm shadow"
		style="--lek-modal-border-width: 0px"
		aria-hidden="true"
	>
		<div class="modal-content">
			<div class="modal-header d-block border-0">
				<div class="text-end">
					<i
						class="str-close cursor-pointer"
						role="button"
						@click="
							() => {
								closeDialog($refs.loginModalDialog)
								form.clearErrors()
								form.reset()
							}
						"
					/>
				</div>
				<h4 class="modal-title fs-4 mt-n3 mb-0">{{ $t('Login') }}</h4>
			</div>

			<form @submit.prevent="submit()">
				<div class="modal-body pt-0">
					<div class="row">
						<div class="col-12 mb-3">
							<div
								v-if="status"
								class="alert alert-info p-3"
							>
								{{ status }}
							</div>

							<input
								v-model="form.email"
								type="email"
								class="form-control"
								:placeholder="$t('Email')"
								required
							/>
							<div v-if="form.errors.email">
								<small
									v-for="(emailError, emailErrorIndex) in form.errors.email"
									:key="`email-error-${emailErrorIndex}`"
									class="text-danger"
									>{{ emailError }}</small
								>
							</div>
						</div>
						<div class="col-12 mb-3">
							<div class="password-wrapper">
								<input
									v-model="form.password"
									:type="!preview ? 'password' : 'text'"
									class="form-control"
									:placeholder="$t('Password')"
									required
									autocomplete="off"
								/>
								<a
									class="icon-wrap"
									href="javascript:void(0);"
									role="button"
									@click="preview = !preview"
								>
									<i
										:class="{
											'bi-eye': preview,
											'bi-eye-slash': !preview,
										}"
									/>
								</a>
								<div v-if="form.errors.password">
									<small
										v-for="(passwordError, passwordErrorIndex) in form.errors.password"
										:key="`password-error-${passwordErrorIndex}`"
										class="text-danger"
										>{{ passwordError }}</small
									>
								</div>
							</div>
						</div>
						<div class="col-12 d-flex">
							<button
								type="submit"
								:disabled="form.processing"
								class="btn btn-gold-500 fw-bold prevent-modal-close"
							>
								{{ $t('Send') }}
							</button>
							<div class="flex-grow-1 text-end">
								<a
									href="javascript:void(0);"
									role="button"
									class="feature-link"
									@click="openDialogById('forgot-password-modal-dialog')"
								>
									{{ $t('Forgot password?') }}
								</a>
							</div>
						</div>
					</div>
				</div>

				<div
					v-if="route().current() !== 'register'"
					class="modal-footer justify-content-between"
				>
					<Link
						:href="route('register')"
						class="feature-link"
					>
						{{ $t("I don't have an account") }}
					</Link>
					<!-- <a
						href="javascript:void(0);"
						role="button"
						class="feature-link"
						@click="openDialogById('register-modal-dialog')"
					>
						{{ $t("I don't have an account") }}
					</a> -->
				</div>
			</form>
		</div>
	</dialog>
</template>

<style lang="scss">
.password-wrapper {
	position: relative;
	.icon-wrap {
		position: absolute;
		right: 20px;
		top: 50%;
		transform: translateY(-50%);
		z-index: 1;
	}
}
</style>
